import { Heading } from "grommet";
import React from "react";
import "../assets/styles/styles.scss";
import FormBlock from "../components/FormBlock";
import SelectInput from "../components/SelectInput";
import TextInput from "../components/TextInput";
import { INDUSTRY } from "../helpers/formLists";

function BusinessInformation({ control }) {
  return (
    <>
      <Heading fill level={2}>
        Business Information
      </Heading>

      <FormBlock title={"What is your occupation?"}>
        <TextInput control={control} name="occupation" label={"Occupation Title"} />
        <SelectInput
          control={control}
          name="industry"
          label="Industry"
          options={INDUSTRY}
        />
        <TextInput control={control} name="company" label={"Company Name"} />
      </FormBlock>
      <FormBlock title={"What is your LinkedIn Profile?"}>
        <TextInput
          optional
          control={control}
          name="linked_in"
          label={"LinkedIn Profile URL"}
        />
      </FormBlock>
    </>
  );
}

export default BusinessInformation;
