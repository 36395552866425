import { Heading, Box, Text, Button as PlainButton } from "grommet";
import React, { useState, Fragment, useEffect, useDebugValue } from "react";
import AddIcon from "../assets/images/ic_add.inline.svg";
import "../assets/styles/styles.scss";
import Button from "../components/Button";
import DateOfBirth from "../components/DateOfBirth";
import FormBlock from "../components/FormBlock";
import TextInput from "../components/TextInput";
import CheckBoxInput from "../components/CheckBoxInput";
import { useDispatch, useSelector } from "react-redux";
import { removeChildIndex } from "../state/formSlice";
import { TITLES, GENRE } from "../helpers/formLists";
import SelectInput from "../components/SelectInput";
import FileInputComponent from "../components/FileInput";

function ChildrenInformation({ control, setValue, getValues }) {
  const form = useSelector((state) => state.form);
  const dispatch = useDispatch();

  let savedChildren = Object.keys(form).filter(
    (item, index) =>
      item.includes(`child_`) && item.includes(`_name`) && form[item] !== ""
  );

  const [children, setChildren] = useState(savedChildren);

  const addChild = () => {
    const temp = [...children];
    temp.push(temp.length);
    setChildren(temp);
  };

  const removeChild = (index) => {
    const temp = [...children];
    temp.splice(index, 1);
    setChildren(temp);

    dispatch(removeChildIndex(index));

    //REMOVE FROM FORM VALUES
    setValue(`child_${index}_name`, "");
    setValue(`child_${index}_date_of_birth`, { day: "", month: "", year: "" });
    setValue(`child_${index}_date_of_birth_day`, "");
    setValue(`child_${index}_date_of_birth_month`, "");
    setValue(`child_${index}_date_of_birth_year`, "");
    setValue(`child_${index}_charging_privileges`, "");
    setValue(`child_${index}_email`, "");
    setValue(`child_${index}_mobile`, "");
    setValue(`child_${index}_gender`, "");
    setValue(`child_${index}_title`, "");    
    setValue(`child_${index}_headshot`,"")
  };

  return (
    <>
      <Heading fill level={2}>
        Children under the age of 30, if applicable
      </Heading>

      {children.map((child, index) => (
        <Fragment key={index}>
          <FormBlock
            title={`Child ${index + 1}: Name & date of birth`}
            key={child}>
            <SelectInput
              control={control}
              name={`child_${index}_title`}
              label="Title"
              options={TITLES}
            />              
            <TextInput
              optional
              control={control}
              name={`child_${index}_name`}
              label={"Full Name"}
              autoComplete={`child_${index}_name`}
            />
            <DateOfBirth
              optional
              setValue={setValue}
              control={control}
              rootName={`child_${index}_date_of_birth`}
            />

            <SelectInput
              control={control}
              name={`child_${index}_gender`}
              label="Gender"
              options={GENRE}
            />
            <TextInput
              control={control}
              name={`child_${index}_email`}
              label={"Email Address"}
              validation={{ pattern: /^\S+@\S+$/i }}
            />
            <TextInput
              control={control}
              name={`child_${index}_mobile`}
              label={"Mobile Phone"}
            />
          </FormBlock>

          <FormBlock title={"Upload Photo/Headshot"}>
              <FileInputComponent
                control={control}
                rootName={`child_${index}_headshot`}
                label="Upload Photo/Headshot"
                setValue={setValue}
              />
          </FormBlock>

          <FormBlock align="center">
            <Box direction="row" fill justify="between" align="center">
              <CheckBoxInput
                control={control}
                pad="10px"
                name={`child_${index}_charging_privileges`}
                label="Charging privileges?"
              />
              {index >= 0 && index === children.length - 1 && (
                <PlainButton onClick={() => removeChild(index)}>
                  <Text
                    color={"brand"}
                    style={{
                      fontFamily: "Americana",
                      fontSize: "14px",
                      maxWidth: null,
                      lineHeight: 1,
                    }}>
                    Remove
                  </Text>
                </PlainButton>
              )}
            </Box>
          </FormBlock>
        </Fragment>
      ))}

      <FormBlock margin={{ top: "36px" }}>
        {children.length < 8 && (
          <Button
            onClick={addChild}
            color={"brand"}
            icon={<AddIcon />}
            text={children.length ? "Add another child" : "Add child"}
          />
        )}
      </FormBlock>
    </>
  );
}

export default ChildrenInformation;
