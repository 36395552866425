import { Heading } from "grommet";
import React, { useEffect, useState } from "react";
import "../assets/styles/styles.scss";
import CheckBoxInput from "../components/CheckBoxInput";
import DateOfBirth from "../components/DateOfBirth";
import FormBlock from "../components/FormBlock";
import SelectInput from "../components/SelectInput";
import TextInput from "../components/TextInput";
import RadioInput from "../components/RadioInput";
import FileInputComponent from "../components/FileInput";
import { COUNTRIES, NATIONALITIES, TITLES, GENRE } from "../helpers/formLists";
import { sameResidence } from "../state/formSlice";
import { useSelector } from "react-redux";
import { useForm, useWatch } from "react-hook-form";

function PersonalInformation({
  control,
  setValue,
  getValues,
  setError,
  clearErrors,
}) {
  const defaultValue = useSelector(
    (state) => state.form["reside_in_palmbeach"]
  );

  const [showPalmBeach, setShowPalmBeach] = useState(false);

  let residenceFields = ["street", "city", "state", "country", "zip"];

  const residenceInfo = useWatch({
    control,
    name: residenceFields,
  });

  const pbSame = useWatch({
    control,
    name: "pb_same",
    defaultValue,
  });

  const residesInPalmBeach = useWatch({
    control,
    name: "reside_in_palmbeach",
    defaultValue,
  });

  useEffect(() => {
    if (residesInPalmBeach === "Seasonally") {
      setShowPalmBeach(true);
    } else {
      setShowPalmBeach(false);
    }
  }, [residesInPalmBeach]);

  useEffect(() => {
    residenceFields.map((field, index) => {
      if (residenceInfo[index] !== "") {
        clearErrors(field);
      }
    });
  }, [residenceInfo]);

  return (
    <>
      <Heading fill level={2}>
        Personal Information
      </Heading>
      <FormBlock title={"What is your title and name?"}>
        <SelectInput
          control={control}
          name="title"
          label="Title"
          options={TITLES}
        />
        <TextInput control={control} name="firstname" label={"First Name"} />
        <TextInput control={control} name="lastname" label={"Last Name"} />
      </FormBlock>

      <FormBlock title={"What is your date of birth?"}>
        <DateOfBirth control={control} rootName="date_of_birth" />
      </FormBlock>

      <FormBlock title={"Gender"}>
        <SelectInput
          control={control}
          name="contact_gender"
          label="Gender"
          options={GENRE}
        />
      </FormBlock>
      
      <FormBlock title={"EU Shoe Size"}>
        <TextInput control={control} name="eu_shoe_size" label={"EU Shoe Size"} />
      </FormBlock>

      <FormBlock title={"Upload Photo/Headshot"}>
        <FileInputComponent
          control={control}
          rootName="headshot_photo_upload"
          label="Upload Photo/Headshot"
          setValue={setValue}
          setError={setError}
        />
      </FormBlock>

      <FormBlock title={"What is your nationality?"}>
        <SelectInput
          control={control}
          name="nationality"
          label="Nationality"
          options={NATIONALITIES}
        />
      </FormBlock>

      <FormBlock title={"What is your relationship status?"}>
        <RadioInput
          name="relationship_status"
          setValue={setValue}
          control={control}
          options={[
            "Single",
            "Married",
            "In a relationship",
            "Civil partnership",
            "Perfer not to say",
          ]}
          direction="column"
        />
      </FormBlock>

      <FormBlock title={"Do you reside in Palm Beach?"}>
        <RadioInput
          name="reside_in_palmbeach"
          setValue={setValue}
          control={control}
          options={["Seasonally", "Full time", "N/A"]}
          direction="column"
        />
      </FormBlock>

      <FormBlock title={"What is your residence address?"}>
        <TextInput control={control} name="street" label={"Street"} />
        <TextInput control={control} name="city" label={"Town/City"} />
        <TextInput control={control} name="state" label={"County/State"} />
        <SelectInput
          control={control}
          name="country"
          label={"Country"}
          options={COUNTRIES}
        />
        <TextInput control={control} name="zip" label={"Zip/Postcode"} />
      </FormBlock>

      {showPalmBeach && (
        <FormBlock title={"What is your Palm Beach residence address?"}>
          <TextInput
            // disabled={pbSame}
            control={control}
            name="pb_street"
            label={"Street"}
          />
          <TextInput
            // disabled={pbSame}
            control={control}
            name="pb_city"
            label={"Town/City"}
          />
          <TextInput
            // disabled={pbSame}
            control={control}
            name="pb_state"
            label={"County/State"}
          />
          <SelectInput
            // disabled={pbSame}
            control={control}
            name="pb_country"
            label={"Country"}
            options={COUNTRIES}
          />
          <TextInput
            // disabled={pbSame}
            control={control}
            name="pb_zip"
            label={"Zip/Postcode"}
          />
        </FormBlock>
      )}

      <FormBlock title={"What are your contact details?"}>
        <TextInput
          control={control}
          name="email"
          label={"Email Address"}
          validation={{ pattern: /^\S+@\S+$/i }}
        />
        <TextInput control={control} name="mobile" label={"Mobile Phone"} />
        <TextInput
          optional
          control={control}
          name="telephone"
          label={"Home Phone"}
        />
      </FormBlock>
    </>
  );
}

export default PersonalInformation;
