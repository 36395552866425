import { Heading } from "grommet";
import React from "react";
import "../assets/styles/styles.scss";
import DateOfBirth from "../components/DateOfBirth";
import FormBlock from "../components/FormBlock";
import TextInput from "../components/TextInput";
import FileInputComponent from "../components/FileInput";
import { COUNTRIES, NATIONALITIES, TITLES, GENRE } from "../helpers/formLists";
import SelectInput from "../components/SelectInput";

function SpouseInformation({ control, setValue }) {
  return (
    <>
      <Heading fill level={2}>
        Spouse Information
      </Heading>

      <FormBlock title={"What is your spouse's title and name?"}>
        <SelectInput
          control={control}
          name="spouse_title"
          label="Title"
          options={TITLES}
        />

        <TextInput
          control={control}
          name="spouse_first_name"
          label={"First Name"}
        />
        <TextInput
          control={control}
          name="spouse_last_name"
          label={"Last Name"}
        />
      </FormBlock>

      <FormBlock title={"What is your spouse's date of birth?"}>
        <DateOfBirth
          setValue={setValue}
          control={control}
          rootName="spouse_date_of_birth"
        />
      </FormBlock>

      <FormBlock title={"Gender"}>
        <SelectInput
          control={control}
          name="spouse_gender"
          label="Gender"
          options={GENRE}
        />
      </FormBlock>

      <FormBlock title={"Spouse EU Shoe Size"}>
        <TextInput control={control} name="spouse_eu_shoe_size" label={"Spouse EU Shoe Size"} />
      </FormBlock>

      <FormBlock title={"Upload Photo/Headshot"}>
        <FileInputComponent
          control={control}
          rootName="spouse_headshot"
          label="Upload Photo/Headshot"
          setValue={setValue}
        />
      </FormBlock>

      <FormBlock title={"What is your spouse's contact details?"}>
        <TextInput
          control={control}
          name="spouse_email"
          label={"Email Address"}
          validation={{ pattern: /^\S+@\S+$/i }}
        />
        <TextInput
          control={control}
          name="spouse_mobile"
          label={"Mobile Phone"}
        />
        <TextInput
          optional
          control={control}
          name="spouse_telephone"
          label={"Home Phone"}
        />
      </FormBlock>
    </>
  );
}

export default SpouseInformation;
