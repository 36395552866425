import { Box, Heading, Image, Paragraph, ResponsiveContext } from "grommet";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import cluboutline from "../assets/images/club_outline@2x.png";
import divider from "../assets/images/divider@2x.png";
import ForwardIcon from "../assets/images/ic_arrow_forward.inline.svg";
import DownloadIcon from "../assets/images/ic_download.inline.svg";
import monkey from "../assets/images/monkey@2x.png";
import "../assets/styles/styles.scss";
import Button from "../components/Button";
import Footer from "../components/Footer";
import FormBlock from "../components/FormBlock";
import HorizontalRule from "../components/HorizontalRule";
import PageHeader from "../components/PageHeader";
import PageTitle from "../components/PageTitle";
import BusinessInformation from "../formBlocks/businessInformation";
import ChildrenInformation from "../formBlocks/childrenInformation";
import PersonalInformation from "../formBlocks/personalInformation";
import SpouseInformation from "../formBlocks/spouseInformation";

import rulesRegulationsPDF from "../static/Carriage-House-Rules-2024.pdf";

import { useDispatch, useSelector } from "react-redux";
import { saveForm } from "../state/formSlice";
import { setStage } from "../state/globalSlice";

const currentYear = new Date().getFullYear();
function Layout(props) {
  const { handleSubmit, control, formState, setError, getValues, setValue, clearErrors, setFocus, reset } = useForm();

  const [showSpouse, setShowSpouse] = useState(false);
  const savedSpouse = useSelector((state) => state.form.relationship_status);
  const relationshipStatus = useWatch({
    control,
    name: "relationship_status", // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    defaultValue: savedSpouse,
  });

  const dispatch = useDispatch();
  const formRef = useRef(null);

  const size = useContext(ResponsiveContext);
  const isMobile = size === "small" || size === "xsmall" ? true : false;

  useEffect(() => {
    console.log(relationshipStatus);
    if (relationshipStatus === "Married" || relationshipStatus === "Civil partnership") {
      setShowSpouse(true);
    } else {
      setShowSpouse(false);
    }
  }, [relationshipStatus]);

  const onSubmit = (data) => {
    dispatch(setStage(1));
  };

  const formSubmit = () => {
    const formValues = getValues();
    dispatch(saveForm(formValues));
    handleSubmit(onSubmit)();
  };

  return (
    <Box fill="horizontal">
      <PageHeader activeSection={1} />
      <Box
        width={{ max: "952px" }}
        fill="horizontal"
        alignSelf="center"
        align="center"
        pad={{
          horizontal: isMobile ? "24px" : "36px",
          top: isMobile ? "104px" : "250px",
        }}
      >
        <PageTitle
          isMobile={isMobile}
          title="Membership"
          image={monkey}
          body={"Please kindly provide the following information to support your candidacy for Membership to Carriage House. Please note the form must be completed and submitted within 30 days from today’s correspondence."}
        />
        <Paragraph margin={{ vertical: "small" }} style={{ maxWidth: "550px" }} textAlign="center" className="small straight">
          This form will take around 5 minutes to complete and all information will be treated in the strictest of confidence.
        </Paragraph>
        <Box fill="horizontal" alignSelf="center" width={{ max: "350px" }} margin={{ top: "48px" }}>
          <Button
            // alignSelf="center"
            href={rulesRegulationsPDF}
            target={"_blank"}
            download
            multiline
            text={"Rules & Regulations"}
            icon={<DownloadIcon />}
          />
        </Box>
        <Image width="10px" src={divider} margin="large" />
        <Box fill="horizontal" align="center">
          <Heading
            className="straight"
            level={2}
            textAlign="center"
            style={{
              fontSize: isMobile ? "21px" : "34px",
              color: "#ce614a",
              textTransform: "uppercase",
            }}
            margin={{ top: "none", bottom: "medium" }}
          >
            Initiation Fee
          </Heading>

          <Paragraph fill textAlign="center"  margin={{ top: "none", bottom: "medium" }}>
            {`$300,000`}
          </Paragraph>
          <Box>

            <Paragraph margin={{ vertical: "small" }} style={{ maxWidth: "550px", opacity: 0.8 }} textAlign="center" className="smallPrint straight">
            The initiation fee of $300,000 + 7% FL sales tax is payable <span style={{ textDecoration: "underline" }}>on election</span> by wire transfer or cheque.
            </Paragraph>
            <Paragraph margin={{ vertical: "small" }} style={{ maxWidth: "550px", opacity: 0.8 }} textAlign="center" className="smallPrint straight">
            Candidates age 45 and under at the time of joining may opt to pay the Initiation Fee in installments over 3 years. The eligible candidate aged 45 and under who wishes to take advantage of these payment terms must sign and return the applicable Addendum, which will be provided for your convenience along with the Membership agreement.
            </Paragraph>
            <Paragraph margin={{ vertical: "small" }} style={{ maxWidth: "550px", opacity: 0.8 }} textAlign="center" className="smallPrint straight">
              *The initiation fee and annual dues are subject to review. At the time of election, the prevailing initiation fee and annual dues will be applicable.
            </Paragraph>
          </Box>
        </Box>
        <Box pad={{ vertical: "medium" }}>
          <Image src={cluboutline} width={isMobile ? "auto" : "300px"} height={isMobile ? "48px" : "auto"} />
        </Box>

        <Paragraph fill style={{ maxWidth: "550px" }} textAlign="center" className="small straight">{`I am interested in acquiring a membership to Carriage House Club (The “Club”) and I am providing the following information to the Club.`}</Paragraph>

        <HorizontalRule />
        <Box fill>
          <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
            <PersonalInformation setError={setError} getValues={getValues} control={control} setValue={setValue} formState={formState} clearErrors={clearErrors} />

            {showSpouse && (
              <>
                <HorizontalRule />
                <SpouseInformation control={control} setValue={setValue} />
              </>
            )}
            <HorizontalRule />
            <ChildrenInformation control={control} setValue={setValue} getValues={getValues} />
            <HorizontalRule />
            <BusinessInformation control={control} setValue={setValue} />
            <FormBlock margin={{ top: "35px" }}>
              <Button onClick={formSubmit} color={"red"} text={"Next Step"} icon={<ForwardIcon />} />
            </FormBlock>
          </form>
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}

export default Layout;
