import React, { useState } from "react";
import { Select } from "grommet";
import styled from "styled-components";
import { Controller } from "react-hook-form";
import FormField from "./FormField";
import { useSelector } from "react-redux";

const StyledSelect = styled(Select)`
  /* border: 1px solid red; */
`;



function SelectInput({ name, label, options, disabled, info = null, control }) {
  const defaultValue = useSelector((state) => state.form[name]);
  const [show, setShow] = useState(defaultValue);

  return control ? (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{ required: true }}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
      }) => {
        return (
          <FormField
            setShow={setShow}
            show={show}
            name={name}
            error={error}
            htmlFor="text-input-id"
            label={label}
            info={info}>
            <StyledSelect
              id={error ? "error" : null}
              disabled={disabled}
              ref={ref}
              name={name}
              options={options}
              placeholder={label}
              onChange={onChange}
              value={value}
            />
          </FormField>
        );
      }}
    />
  ) : null;
}

export default SelectInput;
