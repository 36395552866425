import React, { useEffect, useState } from "react";
import { Box, CheckBox, FormField } from "grommet";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Controller, useController } from "react-hook-form";

const StyledFormField = styled(FormField)`
  padding-left: 0;
  &:focus {
    border: 0px solid transparent;
  }
  label {
    font-size: 16px !important;
  }
  margin-right: 34px;
`;

const StyledCheckBox = styled(CheckBox)`
  /* margin-right: 104px; */
  padding: 0;
  width: 100%;
`;

function CheckBoxComponent({
  name,
  label,
  options,
  control,
  pad = "40px",
  small = false,
  required = false,
  validation = {},
  callBack = () => {},
  ...rest
}) {
  const defaultValue = useSelector((state) => state.form[name]);

  const {
    field: { ref, onChange, value, ...inputProps },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { required },
    defaultValue,
  });

  useEffect(()=>{
    callBack(error);
  },[error])

  return (
    <StyledFormField
      contentProps={{
        pad: { horizontal: "none", top: small ? "none" : pad },
      }}
      name={name}
      >
      <StyledCheckBox
        checked={value || false}
        label={label}
        onChange={(val) => {
          onChange(val);
        }}
        {...rest}
      />
    </StyledFormField>
  );
}

export default CheckBoxComponent;
